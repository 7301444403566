import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Table, Input, Space, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./databasesearch.less";
import { GET_FILTERED_ORDERS } from "../../queries";

const DatabaseSearch = () => {
    const [searchParams, setSearchParams] = useState({
        name: "",
        orderNumber: "",
        uploader: "",
        assignee: "",
        customer: "",
    });
    const [page, setPage] = useState(1);
    const perPage = 20;
    const [sortState, setSortState] = useState({
        field: null,
        order: null,
    });

    // Construct search string for backend
    const buildSearchString = () => {
        const parts = [];
        if (searchParams.name) parts.push(`s3_object_key~${searchParams.name}`);
        if (searchParams.orderNumber)
            parts.push(`order_number~${searchParams.orderNumber}`);
        if (searchParams.uploader)
            parts.push(`uploader_username~${searchParams.uploader}`);
        if (searchParams.assignee)
            parts.push(`assignee_username~${searchParams.assignee}`);
        if (searchParams.customer)
            parts.push(`customer_name~${searchParams.customer}`);
        return parts.join(" ");
    };

    const { loading, data } = useQuery(GET_FILTERED_ORDERS, {
        variables: {
            search: buildSearchString(),
            limit: perPage,
            offset: page,
            sortField: sortState.field,
            sortOrder: sortState.order,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current);

        if (sorter) {
            setSortState({
                field: sorter.field?.[1] || sorter.field, // Handle nested fields
                order: sorter.order === "descend" ? "DESC" : "ASC",
            });
        }
    };

    const columns = [
        {
            title: <Typography.Text strong>データ名</Typography.Text>,
            dataIndex: ["node", "name"],
            key: "name",
            sorter: true,
            render: (text, record) => (
                <a href={`/orders/${record.node.id}`}>{text}</a>
            ),
        },
        {
            title: <Typography.Text strong>伝票番号</Typography.Text>,
            dataIndex: ["node", "orderNumber"],
            key: "orderNumber",
            sorter: true,
        },
        {
            title: <Typography.Text strong>アップロード担当者</Typography.Text>,
            dataIndex: ["node", "user", "username"],
            key: "uploader",
            sorter: true,
        },
        {
            title: <Typography.Text strong>担当者</Typography.Text>,
            dataIndex: ["node", "assignee", "username"],
            key: "assignee",
            sorter: true,
        },
        {
            title: <Typography.Text strong>得意先</Typography.Text>,
            key: "customer",
            dataIndex: ["node", "customer"],
            sorter: true,
            render: (_, record) =>
                record.node.customer
                    ? `${record.node.customer.nameOne} ${record.node.customer.nameTwo}`
                    : "",
        },
        {
            title: <Typography.Text strong>作成日時</Typography.Text>,
            dataIndex: ["node", "createdAt"],
            key: "createdAt",
            sorter: true,
            render: (text) => new Date(text).toLocaleString(),
        },
    ];

    return (
        <div className="database-search">
            <Space direction="vertical" style={{ width: "100%" }}>
                <Space wrap>
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="データ名"
                        value={searchParams.name}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                name: e.target.value,
                            })
                        }
                    />
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="伝票番号"
                        value={searchParams.orderNumber}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                orderNumber: e.target.value,
                            })
                        }
                    />
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="アップロード担当者"
                        value={searchParams.uploader}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                uploader: e.target.value,
                            })
                        }
                    />
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="担当者"
                        value={searchParams.assignee}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                assignee: e.target.value,
                            })
                        }
                    />
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder="得意先名"
                        value={searchParams.customer}
                        onChange={(e) =>
                            setSearchParams({
                                ...searchParams,
                                customer: e.target.value,
                            })
                        }
                    />
                </Space>

                <Typography.Text strong>
                    検索結果: {data?.orders?.totalCount || 0} 件
                </Typography.Text>

                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data?.orders?.edges}
                    rowKey={(record) => record.node.id}
                    pagination={{
                        total: data?.orders?.totalCount,
                        pageSize: perPage,
                        current: page,
                    }}
                    onChange={handleTableChange}
                />
            </Space>
        </div>
    );
};

export default DatabaseSearch;
