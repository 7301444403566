import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { Table, Input, Tooltip, Modal, Descriptions, Typography, Button } from 'antd';
import algoliasearch from 'algoliasearch/lite';
import './detailsearch.less';
import {
    InstantSearch,
    connectSearchBox,
    Highlight,
    Snippet,
    Configure,
    connectInfiniteHits
} from 'react-instantsearch-dom';
import { PATHS } from '../../constants'
import { SearchOutlined } from '@ant-design/icons';

const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
);

const CustomSearchBox = connectSearchBox(({ refine }) => { // declared outside of the main component to prevent resetting upon rerender
    return (
        <Input
            prefix={<SearchOutlined style={{color: "rgb(118, 118, 118)"}} />}
            suffix={<img className="algolia-logo" alt="algolia logo" src={require('../../static/Algolia-logo-blue.png')}></img>}
            className="detail-search-box"
            placeholder="データ名、日付、担当者、得意先名、納品先名、住所、商品内容を検索できます。"
            size="large"
            onChange={(event) => {
                const searchString = event.target.value
                refine(searchString);
            }}
        />
    );
});

const ConditionalSnippet = (hit, attribute) => { //shows full attribute text if no match for snippet
    if (hit._snippetResult?.[attribute]?.matchLevel === 'none') {
        return hit[attribute];
    }

    return <Snippet attribute={attribute} hit={hit}/>;
};

const conditionalHighlight = (hit, attribute) => {
    if (hit._highlightResult?.[attribute]) {
        return <Highlight attribute={attribute} hit={hit}/>;
    }

    return hit[attribute];
}


const DetailSearch = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({}); //this set to the pdf record to be viewed when a row is clicked
    const [buttonState] = useState({
        "name": "primary",
        "timestamp": "primary",
        "uploader.username": "primary",
        "assignee.username": "primary",
        "customer_content": "primary",
        "delivery_destination": "primary",
        "order_details": "primary"
    })
    const [searchableAttributes, setSearchableAttributes] = useState(['name', 'timestamp', 'order_number', 'uploader.username', 'assignee.username', 'customer_content', 'delivery_destination', 'order_details'])

    const changeSearchableAttributes = (attribute) => {
        if (searchableAttributes.includes(attribute)) {
            const filtered = searchableAttributes.filter(e => e !== attribute ? e : null)
            setSearchableAttributes(filtered)
            buttonState[attribute] = "secondary"
        } else {
            setSearchableAttributes([...searchableAttributes, attribute])
            buttonState[attribute] = "primary"
        }

    }

    const openModal = (record) => {
        setCurrentRecord(record);
        setModalVisible(true);
    };

    const closeModal = (e) => {
        setCurrentRecord({});
        setModalVisible(false);
    };

    const columns = [ //ant table columns are defined here
        {
            title: <Typography.Text strong>データ名</Typography.Text>,
            dataIndex: 'name',
            render: (text, record) => {
                return (<a href="#0" onClick={ e=> {
                    openModal(record)
                    e.preventDefault()
                }}>{conditionalHighlight(record, 'name')}</a>)
            }
        },
        {
            title: <Typography.Text strong>アップロード日時</Typography.Text>,
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            defaultSortOrder: 'descend',
            dataIndex: 'timestamp',
            render: (text, record) => {
                if (record._highlightResult?.timestamp?.matchLevel === "full") {
                    return <em className="ais-Highlight-highlighted">{new Date(text).toLocaleString()}</em>
                }
                return new Date(text).toLocaleString()
            }
        },
        {
            title: <Typography.Text strong>伝票番号</Typography.Text>,
            dataIndex: 'order_number',
            render: (text, record) => {
                return (
                        conditionalHighlight(record, 'order_number')
                );
            }
        },
        {
            title: <Typography.Text strong>アップロード担当者</Typography.Text>,
            dataIndex: 'uploader.username',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                if (record._highlightResult?.uploader?.username) {
                    return <Highlight attribute={'uploader.username'} hit={record}/>
                }
                return record.uploader?.username
            }
        },
        {
            title: <Typography.Text strong>担当者</Typography.Text>,
            dataIndex: 'assignee.username',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                if (record._highlightResult?.uploader?.username) {
                    return <Highlight attribute={'assignee.username'} hit={record}/>
                }
                return record.uploader?.username
            }
        },
        {
            title: <Typography.Text strong>得意先</Typography.Text>,
            dataIndex: 'customer_content',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft" title={text}>
                        {ConditionalSnippet(record, 'customer_content')}
                    </Tooltip>
                );
            }
        },
        {
            title: <Typography.Text strong>納品先</Typography.Text>,
            dataIndex: 'delivery_destination',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft" title={text}>
                        {ConditionalSnippet(record, 'delivery_destination')}
                    </Tooltip>
                );
            }
        },
        {
            title: <Typography.Text strong>商品内容</Typography.Text>,
            dataIndex: 'order_details',
            ellipsis: {
                showTitle: false
            },
            render: (text, record) => {
                return (
                    <Tooltip placement="topLeft" title={text}>
                        {ConditionalSnippet(record, 'order_details')}
                    </Tooltip>
                );
            }
        }
    ];

    const InfiniteResults = ({ hits, hasMore, refineNext}) => {
        if (hits.length > 0) {
            return (
                <>
                <Table
                    fixed={"top"}
                    scroll={{ y: 700 }}
                    pagination={{position: ["none"], pageSize: "9999"}}
                    className="detail-search-table"
                    columns={columns}
                    dataSource={hits}
                />
                <Button disabled={hasMore ? false : true} style={{marginTop: "1rem"}} onClick={refineNext}>もっと見る</Button>
                </>
            )
        }
        return <div/>
    }

    const CustomInfiniteResults = connectInfiniteHits(InfiniteResults);

    return (
        <div className="detail-search">
            <InstantSearch //main instantSearch component
                searchClient={searchClient}
                indexName={process.env.REACT_APP_ALGOLIA_INDEX}
            >
                <CustomSearchBox />
                <div className="search-buttons">
                    <Button type={buttonState['name']} onClick={e => changeSearchableAttributes('name')}>データ名</Button>
                    <Button type={buttonState['timestamp']} onClick={e => changeSearchableAttributes('timestamp')}>アップロード日時</Button>
                    <Button type={buttonState['order_number']} onClick={e => changeSearchableAttributes('order_number')}>伝票番号</Button>
                    <Button type={buttonState['uploader.username']} onClick={e => changeSearchableAttributes('uploader.username')}>アップロード担当者</Button>
                    <Button type={buttonState['assignee.username']} onClick={e => changeSearchableAttributes('assignee.username')}>担当者</Button>
                    <Button type={buttonState['customer_content']} onClick={e => changeSearchableAttributes('customer_content')}>得意先</Button>
                    <Button type={buttonState['delivery_destination']} onClick={e => changeSearchableAttributes('delivery_destination')}>納品先住所</Button>
                    <Button type={buttonState['order_details']} onClick={e => changeSearchableAttributes('order_details')}>商品内容</Button>
                </div>
                <CustomInfiniteResults/>
                <Configure
                    restrictSearchableAttributes={searchableAttributes}
                />

                <Modal
                    title={<Typography.Text strong>データ詳細</Typography.Text>}
                    visible={modalVisible}
                    onOk={closeModal}
                    onCancel={closeModal}
                    width={1200}
                    footer={
                        <Link to={PATHS.orderDetails.replace(':orderId', currentRecord.key)}>
                            PDFを確認する
                        </Link>
                    }
                >
                    <Descriptions column={2} labelStyle={{width: "10vw"}} bordered>
                        <Descriptions.Item label="データ名">
                            {conditionalHighlight(currentRecord, 'name')}
                        </Descriptions.Item>
                        <Descriptions.Item label="アップロード日時">
                            {conditionalHighlight(currentRecord, 'timestamp')}
                        </Descriptions.Item>
                        <Descriptions.Item label="担当者">
                            {currentRecord._highlightResult?.uploader?.username ?
                            <Highlight attribute={'uploader.username'} hit={currentRecord}/> : currentRecord.uploader?.username}
                        </Descriptions.Item>
                        <Descriptions.Item label="得意先">
                            {conditionalHighlight(currentRecord, 'customer_content')}
                        </Descriptions.Item>
                        <Descriptions.Item label="納品先住所" span="2">
                            {conditionalHighlight(currentRecord, 'delivery_destination')}
                        </Descriptions.Item>
                        <Descriptions.Item label="商品内容" span="2">
                            {conditionalHighlight(currentRecord, 'order_details')}
                        </Descriptions.Item>
                    </Descriptions>
                </Modal>
            </InstantSearch>
        </div>
    );
};

export default DetailSearch;
