export const SCREWDRIVER_API_ROOT = process.env.REACT_APP_SCREWDRIVER_API_ROOT;

export const ACTIVE_TAB_MAPPING = {
    'uploaded': 'アップロード済み',
    'incomplete': '未完了',
    'backordered': '不足・取寄',
    'complete': '受注入力完了'
}

export const EMAIL_ORDERS_TAB_MAPPING = {
    'inbox': '受信箱',
    'incomplete': '未完了',
    'backordered': '不足・取寄',
    'complete': '受注入力完了'
}

export const PATHS = {
    upload: '/upload',
    login: '/login',
    orders: '/orders',
    emails: '/emails',
    orderDetails: '/orders/:orderId',
    emailDetails: '/emails/:emailId',
    userSettings: '/userSettings',
    newFormat: '/newFormat/:orderId',
    import: '/import',
    products: '/products',
    productDetails: '/products/:productId',
    clients: '/clients',
    dashboard: '/dashboard',
    addCustomer: '/addCustomer',
    addBoundingBox: '/addBoundingBox',
    fixBoundingBox: '/fixBoundingBox',
    formatMatch: '/formatMatch',
    detailSearch: '/detailsearch',
    databaseSearch: '/databaseSearch',
    customers: '/customers',
    customerDetails: '/customers/:customerId',
};

export const NOT_ORDER_FORMATS = {
    地図: '29',
    全て手書きの注文書: '34',
    部品カタログ: '415',
    見積書: '543'
};

export const GREY = '#F5F5F5';
